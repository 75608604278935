import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCaretSquareRight,
  faSuitcaseRolling,
  faUmbrellaBeach,
  faPassport,
  faHeartbeat,
  faScroll,
} from "@fortawesome/free-solid-svg-icons"
import {
  Jumbotron,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap"
import Layout from "../components/layout"
import Background from "./bkk2.jpg"
import SEO from "../components/seo"
const IndexPage = ({ data }) => {
  const productCard = data.allContentfulPages.edges
  return (
    <Layout>
      <SEO title="Home" />
      <Jumbotron
        style={{
          backgroundImage: `url(${Background})`,
          backgroundPosition: "center bottom",
          backgroundSize: "cover",
        }}
      >
        <Container
          fluid
          style={{
            backgroundColor: "rgba(191, 186, 113, 0.5)",
            paddingTop: 300,
            paddingBottom: 15,
          }}
        >
          <Container className="jumboHome">
            <h1
              className="display-3"
              style={{ textAlign: "right", fontWeight: "500" }}
            >
              Enjoy your stay
            </h1>
            <p className="lead" style={{ textAlign: "right" }}>
              In the land of smiles
            </p>
          </Container>
        </Container>
      </Jumbotron>
      <Container>
        {" "}
        <Row style={{ marginTop: 20 }}>
          <Col md={12} style={{ textAlign: "center", marginBottom: 20 }}>
            <h2
              style={{
                textDecoration: "underline",
                textDecorationColor: "#F9FDB4",
              }}
            >
              Worry Free Insurance Plans
            </h2>
            <span className="p">
              No nonsense plans for Expats, Long Stay & Tourists
            </span>
          </Col>

          <Col md={3} sm={6} xs={12}>
            <Card>
              <CardBody style={{ textAlign: "center" }}>
                <CardTitle style={{ fontSize: 18 }}>
                  Long Stay Insurance
                </CardTitle>
                <h1 style={{ margin: 20 }}>
                  <FontAwesomeIcon
                    icon={faUmbrellaBeach}
                    style={{ color: "#343a40" }}
                  />
                </h1>

                <Link to="/long-stay-insurance">
                  <Button color="warning" outline>
                    <b>View Plans</b>
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </Col>
          <Col md={3} sm={6} xs={12}>
            <Card>
              <CardBody style={{ textAlign: "center" }}>
                <CardTitle style={{ fontSize: 18 }}>Travel Insurance</CardTitle>
                <h1 style={{ margin: 20 }}>
                  <FontAwesomeIcon
                    icon={faSuitcaseRolling}
                    style={{ color: "#343a40" }}
                  />
                </h1>

                <Link to="/travel-insurance">
                  <Button color="warning" outline>
                    <b>View Plans</b>
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </Col>
          <Col md={3} sm={6} xs={12}>
            <Card>
              <CardBody style={{ textAlign: "center" }}>
                <CardTitle style={{ fontSize: 18 }}>Expat Insurance</CardTitle>
                <h1 style={{ margin: 20 }}>
                  <FontAwesomeIcon
                    icon={faPassport}
                    style={{ color: "#343a40" }}
                  />
                </h1>

                <Link to="/expat-insurance">
                  <Button color="warning" outline>
                    <b>View Plans</b>
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </Col>
          <Col md={3} sm={6} xs={12}>
            <Card>
              <CardBody style={{ textAlign: "center" }}>
                <CardTitle style={{ fontSize: 18 }}>Life Insurance</CardTitle>
                <h1 style={{ margin: 20 }}>
                  <FontAwesomeIcon
                    icon={faHeartbeat}
                    style={{ color: "#343a40" }}
                  />
                </h1>

                <Link to="/life-insurance">
                  <Button color="warning" outline>
                    <b>View Plans</b>
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: 40, marginBottom: 40 }}>
          <Col md={12} style={{ textAlign: "center" }}>
            <h2
              style={{
                textDecoration: "underline",
                textDecorationColor: "#F9FDB4",
              }}
            >
              Play it safe, get insured.
            </h2>
            <span className="p">This expat found out the hard way</span>
          </Col>
        </Row>
        <Row style={{ marginTop: 40, marginBottom: 40 }}>
          <Col md={12} style={{ textAlign: "center" }}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/DOUpGnty5oc"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query Products {
    allContentfulPages {
      edges {
        node {
          slug
          title
          target
        }
      }
    }
  }
`

export default IndexPage
